<template>
  <div class="source-detail bg-block">
    <Navbar title="货源详情" @clickLeft="handleRouterBack" :customClickLeft="true" />
    <Empty
      v-if="isStoped"
      imgWidth="0.84rem"
      imgHeight="0.78rem"
      colorBlack
      :image="stopImg"
      description="该货源已关闭"
    >
      <template #extra>
        <Button @click="backSoruceList" class="mt-42" size="normal">返回货源大厅</Button>
      </template>
    </Empty>
    <template v-else>
      <!-- <DetailCard :dataDetail="dataDetail" />
      <RouteCard :dataDetail="dataDetail" /> -->

      <div class="pb-72">
        <div class="box-border py-16 pl-24 pr-16 bg-white sender-receiver-card">
          <div class="box-border relative flex flex-col pl-32 sender-item pb-28">
            <!-- <div
              class="flex items-center justify-center flex-none w-24 h-24 rounded-full sender-icon sender bg-primary"
            ></div> -->
            <div class="sender-icon sender">
              <Icon name="icon_yundan_fa" class="text-24" />
            </div>
            <span class="text-18 text-main font-500">{{ addressInfo.senderName }}</span>
            <a class="pt-4 pb-4 text-16 text-primary" :href="`tel:${addressInfo.senderPhone}`">
              {{ addressInfo.senderPhone }}
            </a>
            <span class="text-16 text-muted">{{ senderAddress }}</span>
          </div>
          <div class="box-border relative flex flex-col pl-32">
            <!-- <div
              class="flex items-center justify-center flex-none w-24 h-24 rounded-full receiver-icon receiver bg-success"
            ></div> -->
            <div class="receiver-icon receiver">
              <Icon name="icon_yundan_shou" class="text-24" />
            </div>
            <span class="text-18 text-main font-500">{{ addressInfo.receiverName }}</span>
            <a class="pt-4 pb-4 text-16 text-primary" :href="`tel:${addressInfo.receiverPhone}`">
              {{ addressInfo.receiverPhone }}
            </a>
            <span class="text-16 text-muted">{{ receiverAddress }}</span>
          </div>
        </div>

        <div class="px-24 py-16 mb-16 bg-white mt-14">
          <div class="text-main font-500 text-18">货源信息</div>
          <div class="flex justify-between mt-16 text-16">
            <span class="flex-shrink-0 mr-16 text-muted">货源单号</span>
            <span class="text-main">{{ dataDetail.shippingBillNumber }}</span>
          </div>
          <div v-if="dataDetail.appDisplayShipperInfo === 1" class="flex justify-between mt-16 text-16">
            <span class="flex-shrink-0 mr-16 text-muted">托运方</span>
            <span class="text-main">{{ shipperInfo.shipperName }}</span>
          </div>
          <div class="flex justify-between mt-16 text-16">
            <span class="flex-shrink-0 mr-16 text-muted">线路名称</span>
            <span class="text-main">{{ dataDetail.routeName }}</span>
          </div>
          <div class="flex justify-between mt-16 text-16">
            <span class="flex-shrink-0 mr-16 text-muted">货物名称</span>
            <span class="text-main">{{ goodsInfo.goodsName }}</span>
          </div>
          <div class="flex justify-between mt-16 text-16" v-if="dataDetail.appShowPrice !== 0">
            <span class="flex-shrink-0 mr-16 text-muted">货源价格</span>
            <span class="text-main">{{ goodsUnitPrice }}</span>
          </div>
          <div class="flex justify-between mt-16 text-16">
            <span class="flex-shrink-0 mr-16 text-muted">单车货重</span>
            <span class="text-main"
              >{{ singleVehicleGoodsWeight }}<span class="text-12"> (以实际装卸重量为准)</span></span
            >
          </div>
          <div class="flex justify-between mt-16 text-16">
            <span class="flex-shrink-0 mr-16 text-muted">剩余货量</span>
            <span class="text-main">{{ remainingGoodsQuantity }}</span>
          </div>
        </div>
      </div>

      <div class="btn-box" @click="handleReveice">
        <Button type="primary" size="large" :loading="loading" loading-text="接单中...">确认接单</Button>
      </div>
    </template>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, onBeforeMount, computed, reactive, onUnmounted } from '@vue/composition-api';
import { Button, Dialog, Toast } from 'vant';
import ClientApp from '@/client/index';
import { apiGetCarList, getPlatformAndCompanyAuditConfig } from '@api/mine';
import { apiGetSourceDetail, apiCheckOrder } from '@api/source';
import { useRouterQuery } from '@hook/useRouterQuery';
import { useRouter } from '@/utils/compsitionHack';
import { useUserStore } from '@/store/user';
// 纯ui组件
import DetailCard from '../components/detailCard';
import RouteCard from '../components/routeCard';
import { useSourceStore } from '@/store/source';
import { PageEnum } from '@/enums/pageEnum';

import { cent2yuan } from '@/utils/unit';
import useWeightDeal from '@hook/useWeightDeal';
import { PricingRulesService } from '@/costsService';

export default defineComponent({
  components: {
    Button,
    Dialog,
    DetailCard,
    RouteCard
  },
  setup() {
    onUnmounted(() => {
      ClientApp?.statusBarColor({ color: '#ffffff' });
    });

    // 改变页面背景颜色
    onBeforeMount(async () => {
      ClientApp?.statusBarColor({ color: '#0076FF' });
    });

    const dataDetail = reactive({
      addressInfoDTO: {},
      shippingBillShipperInfoDTO: {},
      driverShippingBillGoodsVO: {},
      pricePlanDTO: {}
    });
    const query = useRouterQuery();
    const isStoped = ref(false);
    const loading = ref(false);
    const router = useRouter();
    const userStore = useUserStore();
    const sourceStore = useSourceStore();
    const authSettingRef = ref(null);

    const stopImg = require('@/assets/source-close.png');
    const handleReveice = async () => {
      try {
        const res = await apiCheckOrder({
          ...query,
          userLat: query.userLat,
          userLng: query.userLng,
          shippingBillId: query.id
        });
        if (!res.data || !res.data.length) {
          handleValidateRules()
            .then(() => {
              if (query.transportBillOperateSource) {
                router.push(
                  `/driver/source/information?id=${query.id}&transportBillOperateSource=${query.transportBillOperateSource}`
                );
              } else {
                router.push(`/driver/source/information?id=${query.id}`);
              }
            })
            .catch(() => {});
        } else {
          Dialog.confirm({
            title: '提示',
            message: '您有未完成的运单，不能重复接单!',
            confirmButtonText: '查看运单',
            cancelButtonText: '好的',
            closeOnClickOverlay: false
          })
            .then(() => {
              if (res.data.length === 1) {
                router.push(`/driver/waybill/detail?id=${res.data[0].id}`);
              } else {
                ClientApp.jumpNativePage(0, 0);
              }
            })
            .catch(() => {});
        }
      } catch {
        return false;
      }
    };
    const backSoruceList = () => {
      ClientApp.jumpNativePage(1); // 跳转到货源列表
    };
    onBeforeMount(async () => {
      await userStore.getUserInfoAction();
      try {
        const res = await apiGetSourceDetail({
          ...query,
          param: '',
          // userLat: query.userLat || 120.2,
          // userLng: query.userLng || 30.3,
          userLat: query.userLat,
          userLng: query.userLng,
          shippingBillId: query.id
        });
        const { data } = res;

        const resSetting = await getPlatformAndCompanyAuditConfig({ companyId: data.companyId });
        authSettingRef.value = resSetting.data;

        await sourceStore.queryCheckVirtualAccount(data.taxSourceId);
        Object.assign(dataDetail, data);
      } catch (err) {
        isStoped.value = true;
      }
      handleValidateRules();
    });
    const handleValidateRules = async () => {
      // 获取配置项
      // platformAuditPostpositionState 开启审核后置功能 1关闭 2开启
      if (userStore?.driverAuthVO?.driverId) {
        // id不存在没进行过认证流程
        return new Promise((resolve, reject) => {
          // 审核后置关闭 也 且司机认证为通过
          if (authSettingRef.value === 1 && userStore?.userInfo?.driverAuthVO?.authStatus !== 2) {
            Dialog.confirm({
              message: '司机审核未通过，无法接单！',
              confirmButtonText: '点击认证',
              closeOnClickOverlay: true
            })
              .then(() => {
                router.push({
                  path: userStore.driverId ? PageEnum.BASE_DRIVER_AUTH_RESULT : PageEnum.BASE_DRIVER_AUTH,
                  query: {
                    needBack: userStore.driverId ? 1 : 0
                  }
                });
              })
              .catch(() => {
                reject();
              });
          } else {
            try {
              apiGetCarList({
                driverId: userStore?.driverAuthVO?.driverId,
                auditStatus: authSettingRef.value === 1 ? 2 : null
              }).then(res => {
                if (!res?.data?.length) {
                  Dialog.confirm({
                    message: '车辆审核未通过，无法接单！',
                    confirmButtonText: '点击认证',
                    closeOnClickOverlay: true
                  })
                    .then(() => {
                      // router.push(PageEnum.BASE_CAR_ADD);
                      router.push(PageEnum.BASE_CAR_LIST);
                    })
                    .catch(() => {
                      reject();
                    });
                } else {
                  resolve();
                }
              });
            } catch (err) {
              Toast.fail(err);
            }
          }
        });
      } else {
        return new Promise((_, reject) => {
          Dialog.confirm({
            message: '司机暂未认证，无法接单！',
            confirmButtonText: '点击认证',
            closeOnClickOverlay: true
          })
            .then(() => {
              router.push(PageEnum.BASE_DRIVER_AUTH);
            })
            .catch(() => {
              reject();
            });
        });
      }
    };
    const handleRouterBack = () => {
      router.push(PageEnum.BASE_SOURCE);
    };

    // 根据计价规则算运费
    const calcCostsByRules = (rules, goodsVo) => {
      const costsRules = { ...rules };
      const { actualWeight, volume, number, weightUnit } = goodsVo;
      const options = {
        weightUnit: weightUnit, // 1: 吨  2: kg
        loadingWeight: actualWeight,
        loadingVolume: volume,
        loadingNumber: number
      };
      const { costs } = PricingRulesService.setRules(costsRules)
        .setOptions(options)
        .build();
      return costs;
    };

    onMounted(() => {
      apiGetSourceDetail({
        ...query,
        param: '',
        // userLat: query.userLat || 120.2,
        // userLng: query.userLng || 30.3,
        userLat: query.userLat,
        userLng: query.userLng,
        shippingBillId: query.id
      })
        .then(res => {
          dataDetail.value = res.data;
        })
        .catch(() => {
          isStoped.value = true;
        });
      handleValidateRules();
    });
    const addressInfo = computed(() => dataDetail.addressInfoDTO);
    const senderAddress = computed(() => {
      const info = addressInfo.value;
      const address =
        (info.senderProvinceName || '') +
        (info.senderCityName || '') +
        (info.senderDistrictName || '') +
        (info.senderLocation || '');
      return address || '';
    });
    const receiverAddress = computed(() => {
      const info = addressInfo.value;
      const address =
        (info.receiverProvinceName || '') +
        (info.receiverCityName || '') +
        (info.receiverDistrictName || '') +
        (info.receiverLocation || '');
      return address || '';
    });
    const shipperInfo = computed(() => dataDetail.shippingBillShipperInfoDTO);
    const goodsInfo = computed(() => dataDetail.driverShippingBillGoodsVO);
    const pricePlanInfoRef = computed(() => dataDetail.pricePlanDTO);
    const goodsUnitPrice = computed(() => {
      const pricePlanInfo = pricePlanInfoRef.value;
      const pricePlanType = pricePlanInfo.pricePlanType;
      let unit = pricePlanInfo.priceUnitTypeStr;
      if (pricePlanType === 1) {
        // 整车需要减去 额外扣减
        const costs = calcCostsByRules(pricePlanInfoRef.value, goodsInfo.value);
        return cent2yuan(costs) + unit;
      }
      if (pricePlanType === 0) {
        return cent2yuan(pricePlanInfo.transportPrice) + unit;
      }
    });
    const singleVehicleGoodsWeight = computed(() => {
      return (
        shipperInfo &&
        parseFloat(
          (dataDetail.publishType === 0
            ? useWeightDeal(
                dataDetail.driverShippingBillGoodsVO.weightUnit,
                dataDetail.driverShippingBillGoodsVO.actualWeight
              )
            : useWeightDeal(dataDetail.driverShippingBillGoodsVO.weightUnit, dataDetail.weightLimit) ||
              dataDetail.volumeLimit / 1000
          ).toFixed(3)
        ) +
          dataDetail.driverShippingBillGoodsVO.weightUnitStr +
          '/车'
      );
    });
    const remainingGoodsQuantity = computed(() => {
      return dataDetail.driverShippingBillGoodsVO.actualWeight
        ? dataDetail.driverShippingBillGoodsVO &&
            parseFloat(
              useWeightDeal(
                dataDetail.driverShippingBillGoodsVO.weightUnit,
                dataDetail.driverShippingBillGoodsVO.actualWeight
              ).toFixed(3)
            ) + dataDetail.driverShippingBillGoodsVO.weightUnitStr
        : '-' + dataDetail.driverShippingBillGoodsVO.weightUnitStr;
    });

    return {
      dataDetail,
      isStoped,
      stopImg,
      backSoruceList,
      handleReveice,
      loading,
      handleValidateRules,
      handleRouterBack,

      addressInfo,
      shipperInfo,
      goodsInfo,
      senderAddress,
      receiverAddress,
      goodsUnitPrice,
      useWeightDeal,
      singleVehicleGoodsWeight,
      remainingGoodsQuantity
    };
  }
});
</script>
<style lang="less" scoped>
.source-detail {
  min-height: 100vh;
  /deep/ .van-nav-bar {
    @apply bg-primary !important;

    &::after {
      content: none;
    }

    .van-icon,
    .van-nav-bar__title {
      color: #fff;
    }
  }

  .sender-receiver-card {
    .bg-light {
      background-color: #f8faff;
    }

    .sender-item {
      &::before {
        position: absolute;
        top: 0.04rem;
        left: 0.12rem;
        z-index: 1;
        width: 0;
        height: 100%;
        border-right: 1px dashed #c6c6c6;
        border-right-width: 1px;
        content: ' ';
      }

      &::after {
        position: absolute;
        top: 0.1rem;
        left: 0.12rem;
        z-index: 2;
        width: 0.04rem;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.5)),
          linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.5));
        background-position: top, bottom;
        background-repeat: no-repeat;
        background-size: 100% 30%;
        content: ' ';
      }
    }

    .receiver-icon,
    .sender-icon {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;

      &::after {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.14rem;
        color: #fff;
        flex-shrink: 0;
      }

      // &.sender {
      //   &::after {
      //     content: '发';
      //   }
      // }

      // &.receiver {
      //   &::after {
      //     content: '收';
      //   }
      // }
    }
  }

  .btn-box {
    margin-top: 0;
  }
}
</style>
